var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plainWhite"},[_c('v-row',[(!_vm.$vuetify.breakpoint.mdAndDown)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-img',{staticClass:"rounded-l-0 rounded-lg",attrs:{"src":require("@/assets/login/login.jpg"),"height":"100vh"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndDown ? 12 : 7}},[_c('v-card',{class:_vm.$vuetify.breakpoint.smAndDown ? 'pd-60' : 'auth-card',attrs:{"flat":""}},[_c('v-card-title',{staticClass:"font-24 justify-center fw-600 greyColor--text mb-3"},[_vm._v("Sign Up")]),_c('v-card-text',[_c('v-form',{ref:"signUpForm"},[_c('v-row',_vm._l((_vm.fields),function(field,i){return _c('v-col',{key:i,attrs:{"cols":field.name != 'monthly_verification' && 6}},[_c('label',{staticClass:"font-12 greyColor--text fw-400"},[_vm._v(_vm._s(field.label))]),(field.name != 'phone_no')?_c('v-text-field',{staticClass:"font-13 fw-500 primary--text",attrs:{"placeholder":field.placeholder,"type":field.type,"dense":"","flat":"","outlined":"","hide-details":"","rules":field.rules
                      ? field.rules
                      : field.type == 'email'
                      ? _vm.emailRules
                      : _vm.requireField,"required":""},model:{value:(_vm.formData[field['name']]),callback:function ($$v) {_vm.$set(_vm.formData, field['name'], $$v)},expression:"formData[field['name']]"}}):_c('vue-tel-input',{staticClass:"font-13 fw-500 primary--text",staticStyle:{"height":"auto"},attrs:{"autoDefaultCountry":"","mode":"international","validCharactersOnly":"","rules":field.rules
                      ? field.rules
                      : field.type == 'email'
                      ? _vm.emailRules
                      : _vm.requireField,"required":""},model:{value:(_vm.formData[field['name']]),callback:function ($$v) {_vm.$set(_vm.formData, field['name'], $$v)},expression:"formData[field['name']]"}})],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"pt-2"},[_c('v-checkbox',{staticClass:"pa-0",attrs:{"color":"primary","hide-details":"","dense":"","ripple":false,"rules":_vm.checkboxRule},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"greyColor--text fw-400 font-13"},[_vm._v("I agree with the Terms & Conditions")])]},proxy:true}]),model:{value:(_vm.formData['terms_and_condition']),callback:function ($$v) {_vm.$set(_vm.formData, 'terms_and_condition', $$v)},expression:"formData['terms_and_condition']"}})],1)])],1)],1)],1),_c('v-card-actions',{staticClass:"mt-4 pa-3"},[_c('v-btn',{staticClass:"textColor--text ml-2 text-transform-none",attrs:{"color":"primary","height":"50px","block":"","depressed":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.register()}}},[_vm._v("Create Account")])],1),_c('back-to-login')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
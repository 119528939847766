<template>
  <div class="plainWhite">
    <v-row>
      <v-col cols="5" v-if="!$vuetify.breakpoint.mdAndDown">
        <v-img
          class="rounded-l-0 rounded-lg"
          src="@/assets/login/login.jpg"
          height="100vh"
        ></v-img>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 7">
        <v-card
          flat
          :class="$vuetify.breakpoint.smAndDown ? 'pd-60' : 'auth-card'"
        >
          <v-card-title
            class="font-24 justify-center fw-600 greyColor--text mb-3"
            >Sign Up</v-card-title
          >
          <v-card-text>
            <!-- Form section for signup fields -->
            <v-form ref="signUpForm">
              <v-row>
                <v-col
                  v-for="(field, i) in fields"
                  :key="i"
                  :cols="field.name != 'monthly_verification' && 6"
                >
                  <label class="font-12 greyColor--text fw-400">{{
                    field.label
                  }}</label>
                  <v-text-field
                    v-if="field.name != 'phone_no'"
                    class="font-13 fw-500 primary--text"
                    v-model="formData[field['name']]"
                    :placeholder="field.placeholder"
                    :type="field.type"
                    dense
                    flat
                    outlined
                    hide-details
                    :rules="
                      field.rules
                        ? field.rules
                        : field.type == 'email'
                        ? emailRules
                        : requireField
                    "
                    required
                  ></v-text-field>

                  <vue-tel-input
                    v-else
                    class="font-13 fw-500 primary--text"
                    autoDefaultCountry
                    mode="international"
                    validCharactersOnly
                    v-model="formData[field['name']]"
                    :rules="
                      field.rules
                        ? field.rules
                        : field.type == 'email'
                        ? emailRules
                        : requireField
                    "
                    required
                    style="height: auto"
                  ></vue-tel-input>
                </v-col>
              </v-row>
              <!-- Checkbox for terms and conditions -->
              <v-row>
                <v-col cols="10">
                  <div class="pt-2">
                    <v-checkbox
                      class="pa-0"
                      v-model="formData['terms_and_condition']"
                      color="primary"
                      hide-details
                      dense
                      :ripple="false"
                      :rules="checkboxRule"
                    >
                      <template v-slot:label>
                        <span class="greyColor--text fw-400 font-13"
                          >I agree with the Terms & Conditions</span
                        >
                      </template></v-checkbox
                    >
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <!-- Button to perform action for creating account -->
          <v-card-actions class="mt-4 pa-3">
            <v-btn
              color="primary"
              height="50px"
              block
              depressed
              :loading="isLoading"
              @click="register()"
              class="textColor--text ml-2 text-transform-none"
              >Create Account</v-btn
            >
          </v-card-actions>
          <!-- Back to login button component -->
          <back-to-login />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  showErrorMessage,
  showNotificationBar,
  showSimpleErrorMessage,
} from "@/utils/showError";
import BackToLogin from "@/components/common/buttons/BackToLogin.vue";
import ApiService from "@/services/Api";
export default {
  components: { BackToLogin },
  data() {
    return {
      isLoading: false,
      formData: {
        terms_and_condition: false,
      },
      fields: [
        {
          label: "First Name*",
          name: "first_name",
          placeholder: "Enter first name",
          type: "text",
        },
        {
          label: "Last Name*",
          name: "last_name",
          placeholder: "Enter last name",
          type: "text",
        },
        {
          label: "Middle Name",
          name: "middle_name",
          placeholder: "Enter middle name",
          type: "text",
          rules: [],
        },
        {
          label: "Company Name*",
          name: "company_name",
          placeholder: "Enter company name",
          type: "text",
        },
        {
          label: "Work Email*",
          name: "email",
          placeholder: "Enter email",
          type: "email",
        },
        {
          label: "Phone No*",
          name: "phone_no",
          placeholder: "Enter phone no",
          type: "text",
          rules: [
            (v) => !!v || "Phone No is required",
            (v) =>
              /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
                v
              ) || "Phone o must be valid",
          ],
        },
        {
          label: "No. Of Required Verifications*",
          name: "monthly_verification",
          placeholder: "Enter monthly verifications",
          type: "number",
        },
      ],
      checkboxRule: [(v) => !!v || "Required"],
      /* Email validation rules for form inputs */
      emailRules: [
        (v) => !!v || "Email is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) ||
          "E-mail must be valid",
      ],
      /* Username validation rules for form inputs */
      nameRule: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length >= 3) || "Name must have at least 3 characters",
      ],
      /* This is general required field validation */
      requireField: [(v) => !!v || "Field is required"],
    };
  },
  methods: {
    /**
     * This method is responsible to create new user to the platform through api request to backend
     * and stores new user in the database. We are sending an email verification link to the user for verification.
     * @param object {fromData}
     */
    register() {
      // If required fields in form are not filled
      if (
        !this.$refs.signUpForm.validate() &&
        this.formData["terms_and_condition"] == false
      ) {
        return showSimpleErrorMessage("Please fill required fields correctly!");
      }
      this.isLoading = true;
      ApiService.PostRequest("/merchant/register", this.formData)
        .then((response) => {
          this.formData = {};
          this.isLoading = false;
          this.$router.push("/");
          showNotificationBar("success", response.data.message);
        })
        .catch((error) => {
          this.isLoading = false;
          showErrorMessage(error);
        });
    },
  },
};
</script>

<style scoped>
.vue-tel-input:focus-within:not(:focus) {
  box-shadow: unset !important;
  border: 2px solid !important;
  border-color: var(--v-primary-base) !important;
}
.vue-tel-input:hover {
  border: 1px solid black !important;
}
.vue-tel-input:active {
  box-shadow: unset !important;
  border: 2px solid !important;
  border-color: var(--v-primary-base) !important;
}
.vue-tel-input {
  border: 1px solid #a6b0b0 !important;
}
</style>
